import PropTypes from 'prop-types';
import classnames from 'classnames';
import { usePopper } from 'react-popper';
import { useState } from 'react';
import { createPortal } from 'react-dom';

const HeadlessDropdown = ({
  renderField,
  renderOptions,
  className,
  dataTestId = '',
  placement = 'bottom-start',
  alignDropdownWidth = false
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = (e) => {
    e?.stopPropagation();
    setIsOpen(false);
  };

  const openDropdown = (e) => {
    e?.stopPropagation();
    setIsOpen(true);
  };

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placement
    }
  );

  return (
    <div className={classnames('', className)}>
      <div
        role="button"
        data-test-id={dataTestId || 'headless-dropdown'}
        tabIndex={0}
        onClick={isOpen ? closeDropdown : openDropdown}
        ref={setReferenceElement}
        className="cursor-pointer select-none">
        {renderField({
          isOpen,
          closeDropdown,
          openDropdown
        })}
      </div>
      {isOpen &&
        createPortal(
          <>
            <div
              role="button"
              tabIndex={0}
              className="fixed left-0 top-0 z-10 flex h-full w-full cursor-default items-center justify-center bg-white-default bg-opacity-0"
              onClick={closeDropdown}></div>
            <div
              className="absolute z-over-intercom my-4 rounded-12 bg-white-default shadow-npl-styles-shadow-02"
              style={{
                ...styles.popper,
                width: alignDropdownWidth
                  ? referenceElement?.clientWidth
                  : 'auto' // Set popper width to match reference element
              }}
              {...attributes.popper}
              ref={setPopperElement}>
              {renderOptions({ isOpen, closeDropdown, openDropdown })}
            </div>
          </>,
          document.body
        )}
    </div>
  );
};

HeadlessDropdown.propTypes = {
  renderField: PropTypes.func.isRequired,
  renderOptions: PropTypes.func.isRequired,
  className: PropTypes.string,
  placement: PropTypes.string,
  alignDropdownWidth: PropTypes.bool
};

export default HeadlessDropdown;
